import React, { ReactElement } from "react";
import { useSelector } from "react-redux";
import Sheet from "../../comps/sheet/Sheet";
import SheetHead from "../../comps/sheet/SheetHead";
import SheetHeadTitle from "../../comps/sheet/SheetHeadTitle";
import {
  Live,
  Player,
  ReduxState,
  VsScreenProps,
} from "../../config/types/types";
import { wsContext } from "../../config/websocket/WebsocketProvider";
import { ReactComponent as VsIcon } from "../../comps/assets/vs2.svg";
import {
  Avatar,
  Button,
  Fab,
  FormControlLabel,
  ListItemAvatar,
  ListItemText,
  makeStyles,
  Menu,
  MenuItem,
  SvgIcon,
  Switch,
  Typography,
} from "@material-ui/core";
import SheetBody from "../../comps/sheet/SheetBody";
import SheetSection from "../../comps/sheet/SheetSection";
import TextField from "../../comps/textfield/TextField";
import SheetHeadSub from "../../comps/sheet/SheetHeadSub";
import CheckIcon from "@material-ui/icons/Check";
import SaveOutlinedIcon from "@material-ui/icons/SaveOutlined";
import { green } from "@material-ui/core/colors";

const mcs = makeStyles((theme) => ({
  team: {
    "& .details": {
      display: "flex",
      alignItems: "center",

      "& .logo": {
        height: 75,
        width: 75,
        marginRight: 20,
        marginBottom: 20,
        backgroundSize: "contain",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      },
    },

    "& .caption": {
      display: "flex",
      marginTop: 20,
    },
    "& .wrapper": {
      display: "grid",
      gridTemplateColumns: "1fr 1fr 1fr 1fr",
      gridGap: 10,
    },
  },

  menu: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr",
  },
  headWrapper: {
    display: "flex",
    alignItems: "center",
    "& h5": {
      flex: 1,
    },
  },
  fabWrapper: {
    margin: theme.spacing(1),
    position: "relative",
  },
  fabProgress: {
    color: green[500],
    position: "absolute",
    top: -6,
    left: -6,
    zIndex: 1,
  },
}));

const VsScreenSettings = () => {
  const c = mcs();
  const ws = React.useContext(wsContext);
  const { vs_screen, match, tournament } = useSelector(
    (state: ReduxState) => state.live
  );
  const [state, setState] = React.useState<VsScreenProps>({
    team1_logo_settings: { scale: 0, x: 0, y: 0 },
    team2_logo_settings: { scale: 0, x: 0, y: 0 },
    team1_player_settings: { scale: 0, x: 0, y: 0, flip_x: false },
    team2_player_settings: { scale: 0, x: 0, y: 0, flip_x: false },
  });
  const [team1Anchor, setTeam1Anchor] = React.useState<null | HTMLElement>(
    null
  );
  const [team2Anchor, setTeam2Anchor] = React.useState<null | HTMLElement>(
    null
  );

  React.useEffect(() => {
    if (!vs_screen) return;
    setState(vs_screen);
  }, [vs_screen]);

  const handleChange = (item: keyof VsScreenProps) => ({
    currentTarget: { name, valueAsNumber },
  }: React.ChangeEvent<HTMLInputElement>) => {
    setState({ ...state, [item]: { ...state[item], [name]: valueAsNumber } });
  };

  const team = (id: number) => {
    return tournament?.participants.find(
      (p) => p.group_player_ids.includes(id) || p.id === id
    );
  };

  const selectPlayer = (team: keyof VsScreenProps, player: Player) => () => {
    setState({ ...state, [team]: player });
    setTeam1Anchor(null);
    setTeam2Anchor(null);
  };

  const apply = () => {
    ws.setLiveSettings({ vs_screen: state } as Partial<Live>);
  };

  return (
    <Sheet>
      <SheetHead
        color="green"
        icon={
          <SvgIcon>
            <VsIcon />
          </SvgIcon>
        }
      >
        <div className={c.headWrapper}>
          <div style={{ flex: 1 }}>
            <SheetHeadTitle>VS Screen</SheetHeadTitle>
            <SheetHeadSub>
              goes to{" "}
              <a
                href="http://aa-uac.web.app/vs"
                style={{ color: "#fff", textDecoration: "none" }}
              >
                /vs
              </a>
            </SheetHeadSub>
          </div>
          <div className={c.fabWrapper}>
            <Fab color="primary" onClick={apply} disabled={vs_screen === state}>
              {vs_screen === state ? (
                <CheckIcon fontSize="large" />
              ) : (
                <SaveOutlinedIcon fontSize="large" />
              )}
            </Fab>
          </div>
        </div>
      </SheetHead>
      <SheetBody>
        <SheetSection className={c.team}>
          <Typography>{team(match?.player1_id ?? 0)?.org_name}</Typography>

          <div className="details">
            <div
              className="logo"
              style={{
                backgroundImage: `url(${team(match?.player1_id ?? 0)?.logo})`,
              }}
            ></div>

            <Button
              variant="contained"
              color="primary"
              startIcon={
                <Avatar
                  style={{ height: 80 }}
                  variant="rounded"
                  src={state.team1_player?.photo_main}
                />
              }
              onClick={({ currentTarget }) => setTeam1Anchor(currentTarget)}
            >
              {state.team1_player?.ign ?? "Select Player"}
            </Button>

            <Menu
              anchorEl={team1Anchor}
              open={Boolean(team1Anchor)}
              onClose={() => setTeam1Anchor(null)}
              classes={{ list: c.menu }}
            >
              {team(match?.player1_id ?? 0)
                ?.players?.filter((p) => Boolean(p.photo_main))
                .map((player) => (
                  <MenuItem
                    key={player.ign}
                    onClick={selectPlayer("team1_player", player)}
                  >
                    <ListItemAvatar>
                      <Avatar
                        style={{ height: 80 }}
                        variant="rounded"
                        src={player.photo_main}
                      />
                    </ListItemAvatar>
                    <ListItemText primary={player.ign} />
                  </MenuItem>
                ))}
            </Menu>
          </div>
          <Typography variant="button" className="caption">
            LOGO
          </Typography>
          <div className="wrapper">
            <TextField
              size="small"
              label="Horizontal Offset"
              type="number"
              value={state.team1_logo_settings.x}
              inputProps={{ step: 5 }}
              name="x"
              onChange={handleChange("team1_logo_settings")}
            />
            <TextField
              size="small"
              label="Vertical Offset"
              type="number"
              value={state.team1_logo_settings.y}
              inputProps={{ step: 5 }}
              name="y"
              onChange={handleChange("team1_logo_settings")}
            />
            <TextField
              size="small"
              label="Scale Multiplyer (%)"
              type="number"
              value={state.team1_logo_settings.scale}
              inputProps={{ step: 1 }}
              name="scale"
              onChange={handleChange("team1_logo_settings")}
            />
          </div>

          <Typography variant="button" className="caption">
            PLAYER
          </Typography>
          <div className="wrapper">
            <TextField
              size="small"
              label="Horizontal Offset"
              type="number"
              value={state.team1_player_settings.x}
              inputProps={{ step: 5 }}
              name="x"
              onChange={handleChange("team1_player_settings")}
            />
            <TextField
              size="small"
              label="Vertical Offset"
              type="number"
              value={state.team1_player_settings.y}
              inputProps={{ step: 5 }}
              name="y"
              onChange={handleChange("team1_player_settings")}
            />
            <TextField
              size="small"
              label="Scale Multiplyer (%)"
              type="number"
              value={state.team1_player_settings.scale}
              inputProps={{ step: 1 }}
              name="scale"
              onChange={handleChange("team1_player_settings")}
            />
            <FormControlLabel
              control={
                <Switch
                  checked={state.team1_player_settings.flip_x}
                  name="flip_x"
                  onChange={({
                    target: { checked },
                  }: React.ChangeEvent<HTMLInputElement>) =>
                    setState({
                      ...state,
                      team1_player_settings: {
                        ...state.team1_player_settings,
                        flip_x: checked,
                      },
                    })
                  }
                  color="primary"
                />
              }
              label="Flip Horizontally"
            />
          </div>
        </SheetSection>

        {/* ================================================================= TEAM 2 */}

        <SheetSection className={c.team}>
          <Typography>{team(match?.player2_id ?? 0)?.org_name}</Typography>

          <div className="details">
            <div
              className="logo"
              style={{
                backgroundImage: `url(${team(match?.player2_id ?? 0)?.logo})`,
              }}
            ></div>

            <Button
              variant="contained"
              color="primary"
              startIcon={
                <Avatar
                  style={{ height: 80 }}
                  variant="rounded"
                  src={state.team2_player?.photo_main}
                />
              }
              onClick={({ currentTarget }) => setTeam2Anchor(currentTarget)}
            >
              {state.team2_player?.ign ?? "Select Player"}
            </Button>

            <Menu
              anchorEl={team2Anchor}
              open={Boolean(team2Anchor)}
              onClose={() => setTeam2Anchor(null)}
              classes={{ list: c.menu }}
            >
              {team(match?.player2_id ?? 0)
                ?.players?.filter((p) => Boolean(p.photo_main))
                .map((player) => (
                  <MenuItem
                    key={player.ign}
                    onClick={selectPlayer("team2_player", player)}
                  >
                    <ListItemAvatar>
                      <Avatar
                        style={{ height: 80 }}
                        variant="rounded"
                        src={player.photo_main}
                      />
                    </ListItemAvatar>
                    <ListItemText primary={player.ign} />
                  </MenuItem>
                ))}
            </Menu>
          </div>
          <Typography variant="button" className="caption">
            LOGO
          </Typography>
          <div className="wrapper">
            <TextField
              size="small"
              label="Horizontal Offset"
              type="number"
              value={state.team2_logo_settings.x}
              inputProps={{ step: 5 }}
              name="x"
              onChange={handleChange("team2_logo_settings")}
            />
            <TextField
              size="small"
              label="Vertical Offset"
              type="number"
              value={state.team2_logo_settings.y}
              inputProps={{ step: 5 }}
              name="y"
              onChange={handleChange("team2_logo_settings")}
            />
            <TextField
              size="small"
              label="Scale Multiplyer (%)"
              type="number"
              value={state.team2_logo_settings.scale}
              inputProps={{ step: 1 }}
              name="scale"
              onChange={handleChange("team2_logo_settings")}
            />
          </div>

          <Typography variant="button" className="caption">
            PLAYER
          </Typography>
          <div className="wrapper">
            <TextField
              size="small"
              label="Horizontal Offset"
              type="number"
              value={state.team2_player_settings.x}
              inputProps={{ step: 5 }}
              name="x"
              onChange={handleChange("team2_player_settings")}
            />
            <TextField
              size="small"
              label="Vertical Offset"
              type="number"
              value={state.team2_player_settings.y}
              inputProps={{ step: 5 }}
              name="y"
              onChange={handleChange("team2_player_settings")}
            />
            <TextField
              size="small"
              label="Scale Multiplyer (%)"
              type="number"
              value={state.team2_player_settings.scale}
              inputProps={{ step: 1 }}
              name="scale"
              onChange={handleChange("team2_player_settings")}
            />
            <FormControlLabel
              control={
                <Switch
                  checked={state.team2_player_settings.flip_x}
                  name="flip_x"
                  onChange={({
                    target: { checked },
                  }: React.ChangeEvent<HTMLInputElement>) =>
                    setState({
                      ...state,
                      team2_player_settings: {
                        ...state.team2_player_settings,
                        flip_x: checked,
                      },
                    })
                  }
                  color="primary"
                />
              }
              label="Flip Horizontally"
            />
          </div>
        </SheetSection>
      </SheetBody>
    </Sheet>
  );
};

export default VsScreenSettings;
