import React, { ChangeEvent } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  Fab,
  FormControlLabel,
  Grid,
  makeStyles,
  Switch,
} from "@material-ui/core";
import { DialogProps } from "../../config/types/dialogsTypes";
import { Match, PollItemProps, ReduxState } from "../../config/types/types";
import { useDocumentData } from "react-firebase-hooks/firestore";
import firebase, { projectFirestore as db } from "../../config/firebase/config";
import { useSelector } from "react-redux";
import Sheet from "../sheet/Sheet";
import SheetHeadTitle from "../sheet/SheetHeadTitle";
import SheetHead from "../sheet/SheetHead";
import SheetBody from "../sheet/SheetBody";
import swal from "sweetalert";
import SheetHeadSub from "../sheet/SheetHeadSub";
import { green } from "@material-ui/core/colors";
import LaunchIcon from "@material-ui/icons/Launch";

import DateFnsUtils from "@date-io/date-fns";
import {
  KeyboardDateTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import MatchPickEmVotes from "./MatchPickEmVotes";
import MatchPickEmTalentVotes from "./MatchPickEmTalentVotes";

interface MatchVotesProps extends DialogProps {
  match?: Match;
  title?: string;
}

const mcs = makeStyles((theme) => ({
  paper: { backgroundColor: "transparent", boxShadow: "none" },
  settings: {},
  teams: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    margin: theme.spacing(3, 0),
    "& .one": {
      borderRight: "1px solid #000",
    },
  },
  team: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(3),

    "& .logo": {
      width: 100,
      height: 100,
      backgroundSize: "contain",
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
    },
  },

  headWrapper: {
    display: "flex",
    alignItems: "center",
    "& h5": {
      flex: 1,
    },
  },
  fabWrapper: {
    margin: theme.spacing(1),
    position: "relative",
  },
  fabProgress: {
    color: green[500],
    position: "absolute",
    top: -6,
    left: -6,
    zIndex: 1,
  },
  actions: {
    "& > *": {
      marginRight: 15,
    },
  },
}));

const MatchPickEm: React.FC<MatchVotesProps> = ({
  open,
  onClose,
  match,
  title,
}) => {
  const c = mcs();
  const { tournament } = useSelector((state: ReduxState) => state.live);
  const docRef = db
    .collection("tournaments")
    .doc(`${tournament?.url}`)
    .collection("poll")
    .doc(`${match?.id}`);
  const [matchPoll, loading] = useDocumentData<PollItemProps>(docRef);
  const [votesDialogState, setVotesDialogState] = React.useState<boolean>(
    false
  );
  const [
    talentVotesDialogState,
    setTalentVotesDialogState,
  ] = React.useState<boolean>(false);
  const [votesDialogTeam, setVotesDialogTeam] = React.useState<
    "team1" | "team2" | "all"
  >("all");
  const [globalLoading, setGlobalLoading] = React.useState<boolean>(false);

  const team = (id?: number) => {
    return tournament?.participants?.find(
      (p) => p.id === id || p.group_player_ids.includes(id ?? 0)
    );
  };

  const [state, setState] = React.useState<PollItemProps>({
    is_published: false,
    match_id: match?.id ?? 0,
    tournament_url: tournament?.url ?? "",
    expiry_date_time: firebase.firestore.Timestamp.now(),
    team1: team(match?.player1_id),
    team2: team(match?.player2_id),
    team1_votes: 0,
    team2_votes: 0,
    vote_ids: [],
    votes: [],
    team1_votes_id: [],
    team2_votes_id: [],
    match_round: match?.round ?? 1,
    is_groups: Boolean(match?.group_id),
    tournament_name: tournament?.name ?? "",
    is_closed: false,
    talent_votes: [],
  });

  React.useEffect(() => {
    if (!matchPoll) return;
    setState(matchPoll);
  }, [matchPoll]);

  const enable = () => {
    docRef.set({
      is_published: true,
      match_id: match?.id,
      // match_data: match,
      tournament_url: tournament?.url ?? "",
      expiry_date_time: firebase.firestore.Timestamp.now(),
      team1: team(match?.player1_id),
      team2: team(match?.player2_id),
      team1_votes: 0,
      team2_votes: 0,
      vote_ids: [],
      votes: [],
      team1_votes_id: [],
      team2_votes_id: [],
      match_round: match?.round ?? 1,
      is_groups: Boolean(match?.group_id),
      tournament_name: tournament?.name ?? "",
      is_closed: false,
      talent_votes: [],
    });
  };

  const removePoll = () => {
    swal({
      title: "Are you sure?",
      text: "All of the votes and settings will be lost",
      dangerMode: true,
      buttons: ["Nonono", true],
    }).then((value) => {
      if (!value) return;
      docRef.delete();
    });
  };
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const handleDateChange = (date: Date | null) => {
    setState({
      ...state,
      expiry_date_time: firebase.firestore.Timestamp.fromDate(
        date ?? new Date()
      ),
    });
  };

  const navigate = () => {
    if (window.location.hostname === "localhost") {
      window.open(
        `http://localhost:3001/${tournament?.url}/${match?.id}`,
        "_blank"
      );
    } else {
      window.open(
        `https://vote.oasisgaming.gg/${tournament?.url}/${match?.id}`,
        "_blank"
      );
    }
  };

  const save = () => {
    setGlobalLoading(true);
    docRef.set(state).then(() => {
      setGlobalLoading(false);
      swal({
        title: "Successfully updated Pick'Em settings",
        icon: "success",
      });
    });
  };

  const openVotesDialog = (team: "team1" | "team2" | "all") => () => {
    setVotesDialogTeam(team);
    setVotesDialogState(true);
  };

  return (
    <Dialog open={open} onClose={onClose} classes={{ paper: c.paper }}>
      <Sheet loading={loading || globalLoading}>
        <SheetHead color="blue">
          <div className={c.headWrapper}>
            <SheetHeadTitle>Pick'Em: {title}</SheetHeadTitle>
            <div className={c.fabWrapper}>
              <Fab size="small" onClick={navigate}>
                <LaunchIcon />
              </Fab>
            </div>
          </div>
        </SheetHead>

        <SheetBody>
          {matchPoll ? (
            <>
              <Grid container spacing={3} style={{ padding: "0px 10px" }}>
                {/* is Published */}
                <Grid item xs={6}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={state?.is_published}
                        onChange={handleChange}
                        name="is_published"
                        color="primary"
                      />
                    }
                    label="Is Published?"
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={state?.is_closed}
                        onChange={handleChange}
                        name="is_closed"
                        color="secondary"
                      />
                    }
                    label="Is Closed?"
                  />
                </Grid>

                {/* Expiry */}
                {/* <Grid item xs={12}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDateTimePicker
                    margin="normal"
                    id="date-picker-dialog"
                    label="Match Schedule"
                    format="MM/dd/yyyy — hh:mm a"
                    value={state.expiry_date_time.toDate()}
                    onChange={handleDateChange}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </MuiPickersUtilsProvider>
              </Grid> */}

                {/* Teams */}
                <Grid item xs={12}>
                  <div className={c.teams}>
                    <div className={c.team + " one"}>
                      <div
                        className="logo"
                        style={{
                          backgroundImage: `url(${matchPoll?.team1?.logo})`,
                        }}
                      ></div>
                      <Button
                        variant="outlined"
                        onClick={openVotesDialog("team1")}
                        disabled={!Boolean(matchPoll?.team1_votes)}
                      >
                        {matchPoll?.team1_votes} Votes
                      </Button>
                    </div>
                    <div className={c.team}>
                      <div
                        className="logo"
                        style={{
                          backgroundImage: `url(${matchPoll?.team2?.logo})`,
                        }}
                      ></div>
                      <Button
                        variant="outlined"
                        onClick={openVotesDialog("team2")}
                        disabled={!Boolean(matchPoll?.team2_votes)}
                      >
                        {matchPoll?.team2_votes} Votes
                      </Button>
                    </div>
                  </div>
                </Grid>

                {/* Actions */}
                {/* <Grid item xs={12} className={c.actions}>
                
              </Grid> */}
              </Grid>

              <DialogActions>
                <Button variant="contained" color="primary" onClick={save}>
                  Save Changes
                </Button>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={openVotesDialog("all")}
                >
                  See All Votes
                </Button>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => setTalentVotesDialogState(true)}
                >
                  Talent Votes
                </Button>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={removePoll}
                >
                  Delete poll
                </Button>
              </DialogActions>
            </>
          ) : (
            <div>
              <div>Poll is not enabled yet.</div>
              <Button variant="contained" onClick={enable}>
                Enable
              </Button>
            </div>
          )}
        </SheetBody>
      </Sheet>
      <MatchPickEmVotes
        open={votesDialogState}
        onClose={() => setVotesDialogState(false)}
        poll={matchPoll}
        team={votesDialogTeam}
      />
      <MatchPickEmTalentVotes
        open={talentVotesDialogState}
        onClose={() => setTalentVotesDialogState(false)}
        poll={matchPoll}
      />
    </Dialog>
  );
};

export default MatchPickEm;
