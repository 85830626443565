import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  makeStyles,
} from "@material-ui/core";
import React from "react";
import { PollItemProps } from "../../config/types/types";

interface PickEmVotesDialogProps {
  open: boolean;
  onClose?: () => any;
  poll?: PollItemProps;
  team?: "team1" | "team2" | "all";
}

const mcs = makeStyles((theme) => ({
  votesDialogPaper: {
    // padding: theme.spacing(3),
  },
}));

const MatchPickEmVotes: React.FC<PickEmVotesDialogProps> = ({
  open,
  onClose,
  poll,
  team = "all",
}) => {
  const c = mcs();

  const gotoUrl = (url: string) => () => {
    window.open("https://facebook.com/profile.php?id=" + url, "_blank");
  };

  const getVotedTeam = (id: number) => {
    if (id === poll?.team1?.id) return poll?.team1?.university_acronym;
    return poll?.team2?.university_acronym;
  };
  return (
    <Dialog
      open={open}
      onClose={onClose}
      classes={{ paper: c.votesDialogPaper }}
    >
      <DialogTitle>
        {team !== "all" ? poll && poll[team]?.org_name : "all"} Votes
      </DialogTitle>
      <DialogContent>
        <List>
          {team !== "all"
            ? poll?.votes
                .filter((v) => v.vote === team)
                .map((v, i) => (
                  <ListItem button key={i} onClick={gotoUrl(v.fb_id + "")}>
                    <ListItemAvatar>
                      <Avatar src={v.picture} />
                    </ListItemAvatar>
                    <ListItemText
                      primary={v.name}
                      secondary={"Picked " + getVotedTeam(v.vote_team_id)}
                    ></ListItemText>
                  </ListItem>
                ))
            : poll?.votes.map((v, i) => (
                <ListItem button key={i} onClick={gotoUrl(v.fb_id + "")}>
                  <ListItemAvatar>
                    <Avatar src={v.picture} />
                  </ListItemAvatar>
                  <ListItemText
                    primary={v.name}
                    secondary={"Picked " + getVotedTeam(v.vote_team_id)}
                  ></ListItemText>
                </ListItem>
              ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button color="primary">Get Random Winner</Button>
      </DialogActions>
    </Dialog>
  );
};

export default MatchPickEmVotes;
